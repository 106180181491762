type Props = {
  color?: string;
  width?: string;
  height?: string;
  margin?: string;
};

const BreadcrumbSlashSvgIcon = ({ color, width, height, margin }: Props) => {
  return (
    <svg
      style={{ margin: margin ? margin : "0" }}
      width={width ? width : "8"}
      height={height ? height : "14"}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.90731 0.435708C7.1543 0.559203 7.25441 0.85954 7.13092 1.10653L1.13092 13.1065C1.00742 13.3535 0.707086 13.4536 0.460096 13.3301C0.213107 13.2066 0.112995 12.9063 0.23649 12.6593L6.23649 0.659315C6.35998 0.412326 6.66032 0.312214 6.90731 0.435708Z"
        fill={color ? color : "#ABABAB"}
      />
    </svg>
  );
};

export default BreadcrumbSlashSvgIcon;
