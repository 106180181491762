import React, { createContext, ReactElement, useState } from "react";
import { NavDropDown } from "types/AlgoliaSearch";

interface Props {
  children: React.ReactNode;
}

const initialState: NavDropDown = {
  dropDownMenu: true,
  setDropDownMenu: () => null,
};

export const NavDropdownContext = createContext<NavDropDown>(initialState);

export default function NavDropdownProvider({ children }: Props): ReactElement {
  const [dropDownMenu, setDropDownMenu] = useState<boolean>(true);

  return (
    <NavDropdownContext.Provider
      value={{
        dropDownMenu,
        setDropDownMenu,
      }}
    >
      {children}
    </NavDropdownContext.Provider>
  );
}
