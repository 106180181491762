import { useMediaQuery } from "react-responsive";

type Props = {
  width?: string;
  bgColor?: string;
};

const AppStoreSvgImg = ({ width, bgColor }: Props) => {
  const smallScreen = useMediaQuery({
    maxWidth: 895,
  });

  return (
    <svg
      width={width ? width : smallScreen ? "120" : "145"}
      height="64"
      viewBox="0 0 191 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_84_88008)">
        <path
          d="M12.2197 63.9966C11.728 63.9966 11.2481 63.9904 10.7603 63.9785C9.74973 63.9649 8.74148 63.8737 7.74382 63.7061C6.81363 63.5395 5.91243 63.2309 5.07009 62.7891C4.23551 62.3513 3.47428 61.777 2.81559 61.0883C2.14736 60.4075 1.59103 59.6185 1.16865 58.752C0.741609 57.8792 0.446068 56.9441 0.292328 55.9791C0.126305 54.942 0.036482 53.8933 0.0236281 52.842C0.0133952 52.4891 0 51.3143 0 51.3143V12.6644C0 12.6644 0.0142669 11.5076 0.0237078 11.1677C0.0360147 10.1181 0.125319 9.07107 0.290847 8.03551C0.444871 7.06764 0.740635 6.1299 1.16791 5.25464C1.58874 4.38849 2.14198 3.59863 2.80618 2.9157C3.46963 2.22628 4.2333 1.64903 5.06935 1.205C5.90975 0.764772 6.80918 0.457986 7.7375 0.29492C8.7384 0.125209 9.75018 0.0334558 10.7643 0.0204288L12.2205 0H178.073L179.546 0.0212487C180.551 0.0336284 181.553 0.124563 182.545 0.29328C183.483 0.458392 184.392 0.767315 185.242 1.20989C186.916 2.10476 188.279 3.5205 189.139 5.25872C189.56 6.12791 189.851 7.05807 190.003 8.01756C190.17 9.06155 190.264 10.1169 190.283 11.1751C190.288 11.6489 190.288 12.1578 190.288 12.6644C190.301 13.2918 190.301 13.889 190.301 14.4911V49.5071C190.301 50.115 190.301 50.7081 190.288 51.3061C190.288 51.8502 190.288 52.3486 190.282 52.8616C190.263 53.9009 190.171 54.9373 190.006 55.9627C189.855 56.9351 189.561 57.8775 189.135 58.7565C188.709 59.614 188.156 60.3967 187.495 61.0753C186.836 61.7674 186.074 62.3451 185.237 62.7863C184.39 63.2309 183.482 63.5412 182.545 63.7061C181.548 63.8748 180.539 63.966 179.529 63.9785C179.056 63.9904 178.561 63.9966 178.081 63.9966L176.331 64L12.2197 63.9966Z"
          fill={bgColor ? bgColor : "black"}
        />
        <path
          d="M38.1466 32.2891C38.1813 29.5908 39.5917 27.0983 41.8837 25.6851C40.4312 23.6044 38.0856 22.3325 35.5544 22.2531C32.8914 21.9728 30.3097 23.8514 28.9527 23.8514C27.5694 23.8514 25.4801 22.2809 23.2302 22.3274C20.27 22.4233 17.5771 24.0704 16.1369 26.6662C13.0698 31.9921 15.3575 39.8193 18.2955 44.1243C19.7654 46.2324 21.4833 48.5871 23.7312 48.5036C25.9308 48.4121 26.7524 47.0968 29.4075 47.0968C32.038 47.0968 32.8088 48.5036 35.1023 48.4505C37.4626 48.4121 38.9497 46.3331 40.3681 44.2051C41.4242 42.7031 42.2369 41.043 42.776 39.2863C39.9727 38.0971 38.1498 35.342 38.1466 32.2891Z"
          fill="white"
        />
        <path
          d="M33.8148 19.4222C35.1018 17.8727 35.7358 15.881 35.5823 13.8701C33.6161 14.0772 31.7999 15.0197 30.4955 16.5099C29.2076 17.98 28.5551 19.9034 28.6818 21.8564C30.6743 21.8769 32.5662 20.9798 33.8148 19.4222Z"
          fill="white"
        />
        <path
          d="M65.9516 43.1674H58.4455L56.6428 48.506H53.4634L60.5734 28.7544H63.8765L70.9867 48.506H67.7527L65.9516 43.1674ZM59.2226 40.7039H65.1729L62.2396 32.0396H62.1575L59.2226 40.7039Z"
          fill="white"
        />
        <path
          d="M86.3415 41.3065C86.3415 45.7815 83.9537 48.6566 80.3499 48.6566C78.4871 48.7543 76.7325 47.7758 75.8325 46.1372H75.764V53.2698H72.8171V34.1055H75.67V36.5007H75.7238C76.6639 34.8723 78.4203 33.8942 80.2955 33.9548C83.9396 33.9548 86.3415 36.8439 86.3415 41.3065ZM83.3126 41.3065C83.3126 38.391 81.81 36.4742 79.5179 36.4742C77.2666 36.4742 75.7515 38.4314 75.7515 41.3065C75.7515 44.208 77.2666 46.1512 79.5179 46.1512C81.81 46.1512 83.3126 44.2484 83.3126 41.3065Z"
          fill="white"
        />
        <path
          d="M102.145 41.3065C102.145 45.7815 99.7562 48.6566 96.1524 48.6566C94.2896 48.7543 92.535 47.7758 91.635 46.1371H91.567V53.2698H88.6196V34.1055H91.4724V36.5006H91.5268C92.467 34.8723 94.2233 33.8942 96.0986 33.9548C99.7426 33.9548 102.145 36.8439 102.145 41.3065ZM99.1151 41.3065C99.1151 38.391 97.613 36.4742 95.3209 36.4742C93.069 36.4742 91.5546 38.4314 91.5546 41.3065C91.5546 44.208 93.069 46.1512 95.3209 46.1512C97.613 46.1512 99.1151 44.2484 99.1151 41.3065Z"
          fill="white"
        />
        <path
          d="M112.588 43.0029C112.807 44.9616 114.704 46.2477 117.297 46.2477C119.781 46.2477 121.568 44.9615 121.568 43.1954C121.568 41.6624 120.49 40.7444 117.938 40.1153L115.386 39.4987C111.769 38.6226 110.091 36.9264 110.091 34.174C110.091 30.766 113.052 28.4253 117.256 28.4253C121.417 28.4253 124.27 30.766 124.366 34.174H121.391C121.213 32.2029 119.589 31.013 117.214 31.013C114.84 31.013 113.216 32.2169 113.216 33.9689C113.216 35.3653 114.253 36.187 116.791 36.816L118.961 37.3504C123.002 38.3088 124.68 39.9366 124.68 42.8257C124.68 46.521 121.746 48.8353 117.078 48.8353C112.711 48.8353 109.762 46.5753 109.572 43.0027L112.588 43.0029Z"
          fill="white"
        />
        <path
          d="M131.041 30.6973V34.1052H133.771V36.446H131.041V44.3848C131.041 45.6181 131.588 46.1928 132.788 46.1928C133.112 46.1871 133.435 46.1643 133.757 46.1244V48.4513C133.218 48.5524 132.669 48.5982 132.12 48.5879C129.214 48.5879 128.08 47.4929 128.08 44.7001V36.446H125.992V34.1052H128.08V30.6973H131.041Z"
          fill="white"
        />
        <path
          d="M135.353 41.3063C135.353 36.7754 138.013 33.9282 142.162 33.9282C146.325 33.9282 148.973 36.7753 148.973 41.3063C148.973 45.8497 146.339 48.6844 142.162 48.6844C137.987 48.6844 135.353 45.8497 135.353 41.3063ZM145.97 41.3063C145.97 38.1982 144.55 36.3637 142.162 36.3637C139.774 36.3637 138.355 38.2121 138.355 41.3063C138.355 44.4268 139.774 46.2473 142.162 46.2473C144.55 46.2473 145.97 44.4268 145.97 41.3063Z"
          fill="white"
        />
        <path
          d="M151.403 34.1054H154.214V36.5564H154.282C154.674 34.9786 156.115 33.8927 157.736 33.9547C158.076 33.9535 158.414 33.9905 158.746 34.0649V36.8298C158.317 36.6984 157.869 36.6381 157.421 36.6512C156.555 36.6159 155.716 36.9623 155.126 37.5992C154.536 38.2362 154.252 39.1002 154.35 39.9643V48.5058H151.403V34.1054Z"
          fill="white"
        />
        <path
          d="M172.333 44.2762C171.937 46.8904 169.398 48.6844 166.151 48.6844C161.974 48.6844 159.381 45.8776 159.381 41.3746C159.381 36.8577 161.988 33.9282 166.027 33.9282C169.999 33.9282 172.497 36.6651 172.497 41.0314V42.0442H162.357V42.2227C162.262 43.2948 162.631 44.3561 163.37 45.1365C164.109 45.9169 165.146 46.3413 166.219 46.3017C167.649 46.4361 168.998 45.6124 169.535 44.2762H172.333ZM162.371 39.9783H169.549C169.602 39.0149 169.253 38.0729 168.584 37.3791C167.915 36.6853 166.989 36.3027 166.027 36.3234C165.058 36.3176 164.126 36.7004 163.439 37.3867C162.753 38.073 162.368 39.006 162.371 39.9783Z"
          fill="white"
        />
        <path
          d="M58.8533 13.8872C60.1044 13.7972 61.33 14.2746 62.1926 15.1881C63.0552 16.1016 63.4635 17.3549 63.3061 18.603C63.3061 21.635 61.6721 23.3779 58.8533 23.3779H55.4355V13.8872H58.8533ZM56.9055 22.0357H58.6896C59.5833 22.0893 60.4538 21.736 61.0593 21.0738C61.6647 20.4117 61.9406 19.5112 61.8097 18.6223C61.9309 17.7373 61.6511 16.8442 61.0474 16.1875C60.4436 15.5307 59.5788 15.1789 58.6896 15.2284H56.9055V22.0357Z"
          fill="white"
        />
        <path
          d="M64.9661 19.7938C64.8438 18.51 65.4572 17.2676 66.5497 16.5876C67.6422 15.9076 69.0248 15.9076 70.1173 16.5876C71.2098 17.2676 71.8238 18.51 71.7014 19.7938C71.826 21.0789 71.2127 22.3237 70.1196 23.0052C69.0259 23.6866 67.6417 23.6866 66.548 23.0052C65.4544 22.3237 64.8415 21.0789 64.9661 19.7938ZM70.2515 19.7938C70.2515 18.2413 69.5566 17.3334 68.3361 17.3334C67.111 17.3334 66.4217 18.2413 66.4217 19.7938C66.4217 21.3587 67.111 22.2596 68.3361 22.2596C69.5566 22.2596 70.2515 21.3525 70.2515 19.7938Z"
          fill="white"
        />
        <path
          d="M80.6542 23.3776H79.1924L77.7164 18.1026H77.6048L76.1351 23.3776H74.6875L72.7188 16.2153H74.1483L75.4277 21.6805H75.5331L77.0011 16.2153H78.353L79.8211 21.6805H79.9326L81.2058 16.2153H82.615L80.6542 23.3776Z"
          fill="white"
        />
        <path
          d="M84.2705 16.2157H85.627V17.3534H85.7323C86.0988 16.5157 86.9545 16.0033 87.8636 16.0774C88.567 16.0243 89.2568 16.2951 89.7377 16.8134C90.2185 17.3316 90.4383 18.041 90.3352 18.7413V23.3777H88.9255V19.0962C88.9255 17.9452 88.4271 17.3728 87.385 17.3728C86.9075 17.3506 86.4442 17.5384 86.1163 17.8873C85.789 18.2362 85.6292 18.7112 85.6796 19.1879V23.3778H84.2705V16.2157Z"
          fill="white"
        />
        <path d="M92.5808 13.4194H93.9899V23.3776H92.5808V13.4194Z" fill="white" />
        <path
          d="M95.949 19.7935C95.8267 18.5096 96.4407 17.2672 97.5332 16.5872C98.6257 15.9071 100.008 15.9071 101.101 16.5872C102.193 17.2672 102.807 18.5096 102.685 19.7935C102.809 21.0786 102.196 22.3234 101.102 23.0049C100.009 23.6863 98.6246 23.6863 97.5315 23.0049C96.4378 22.3234 95.8244 21.0786 95.949 19.7935ZM101.234 19.7935C101.234 18.2409 100.539 17.333 99.319 17.333C98.0939 17.333 97.4046 18.2409 97.4046 19.7935C97.4046 21.3584 98.0939 22.2593 99.319 22.2593C100.539 22.2593 101.234 21.3522 101.234 19.7935Z"
          fill="white"
        />
        <path
          d="M104.169 21.3521C104.169 20.0629 105.125 19.3196 106.824 19.214L108.759 19.1021V18.4839C108.759 17.7275 108.26 17.3003 107.297 17.3003C106.51 17.3003 105.965 17.59 105.808 18.0964H104.444C104.588 16.8662 105.742 16.0771 107.362 16.0771C109.152 16.0771 110.162 16.9711 110.162 18.4839V23.3776H108.805V22.3711H108.693C108.233 23.1061 107.413 23.5357 106.549 23.4956C105.943 23.5588 105.338 23.3619 104.885 22.9535C104.431 22.5451 104.171 21.9634 104.169 21.3521ZM108.759 20.7401V20.1413L107.015 20.2531C106.031 20.3192 105.585 20.6547 105.585 21.2861C105.585 21.9307 106.143 22.3058 106.909 22.3058C107.364 22.3519 107.817 22.2112 108.165 21.9161C108.514 21.621 108.728 21.1966 108.759 20.7401Z"
          fill="white"
        />
        <path
          d="M112.014 19.7934C112.014 17.5302 113.174 16.0966 114.978 16.0966C115.889 16.0544 116.743 16.544 117.168 17.3531H117.273V13.4194H118.683V23.3776H117.332V22.2459H117.221C116.763 23.0483 115.899 23.5296 114.979 23.4956C113.162 23.4956 112.014 22.0619 112.014 19.7934ZM113.47 19.7934C113.47 21.3124 114.184 22.2266 115.378 22.2266C116.566 22.2266 117.3 21.2993 117.3 19.7996C117.3 18.3069 116.558 17.3664 115.378 17.3664C114.191 17.3664 113.47 18.2867 113.47 19.7934Z"
          fill="white"
        />
        <path
          d="M124.514 19.7938C124.391 18.51 125.005 17.2676 126.097 16.5876C127.19 15.9076 128.572 15.9076 129.665 16.5876C130.757 17.2676 131.371 18.51 131.249 19.7938C131.373 21.0789 130.76 22.3237 129.667 23.0052C128.573 23.6866 127.189 23.6866 126.095 23.0052C125.002 22.3237 124.389 21.0789 124.514 19.7938ZM129.799 19.7938C129.799 18.2413 129.104 17.3334 127.883 17.3334C126.658 17.3334 125.969 18.2413 125.969 19.7938C125.969 21.3587 126.658 22.2596 127.883 22.2596C129.104 22.2596 129.799 21.3525 129.799 19.7938Z"
          fill="white"
        />
        <path
          d="M133.139 16.2157H134.496V17.3534H134.602C134.967 16.5157 135.823 16.0033 136.732 16.0774C137.436 16.0243 138.126 16.2951 138.607 16.8134C139.088 17.3316 139.308 18.041 139.204 18.7413V23.3777H137.795V19.0962C137.795 17.9452 137.296 17.3728 136.254 17.3728C135.776 17.3506 135.313 17.5384 134.986 17.8873C134.658 18.2362 134.498 18.7112 134.549 19.1879V23.3778H133.139V16.2157Z"
          fill="white"
        />
        <path
          d="M147.167 14.4321V16.2479H148.715V17.4385H147.167V21.1213C147.167 21.8715 147.476 22.2001 148.177 22.2001C148.357 22.1995 148.536 22.1886 148.715 22.1675V23.3448C148.461 23.3903 148.205 23.4144 147.948 23.417C146.38 23.417 145.756 22.8641 145.756 21.4832V17.4384H144.623V16.2478H145.756V14.4321H147.167Z"
          fill="white"
        />
        <path
          d="M150.639 13.4194H152.036V17.3663H152.148C152.532 16.5213 153.402 16.0082 154.325 16.0833C155.025 16.0451 155.705 16.3219 156.181 16.8382C156.657 17.3546 156.878 18.0567 156.785 18.7534V23.3776H155.374V19.1021C155.374 17.9581 154.842 17.3788 153.847 17.3788C153.356 17.3384 152.873 17.5172 152.527 17.8673C152.18 18.2173 152.005 18.7031 152.049 19.1945V23.3776H150.639V13.4194Z"
          fill="white"
        />
        <path
          d="M165.002 21.4439C164.607 22.7948 163.303 23.6677 161.908 23.5159C160.956 23.5411 160.04 23.1452 159.405 22.4334C158.77 21.7214 158.479 20.7655 158.609 19.8191C158.482 18.8701 158.772 17.9126 159.404 17.1947C160.036 16.4768 160.947 16.0691 161.901 16.0773C163.889 16.0773 165.087 17.4388 165.087 19.6878V20.181H160.045V20.2603C160 20.7917 160.181 21.3174 160.543 21.708C160.905 22.0987 161.415 22.318 161.947 22.3121C162.636 22.3951 163.307 22.0521 163.645 21.4438L165.002 21.4439ZM160.045 19.1357H163.652C163.688 18.6496 163.517 18.1709 163.183 17.8176C162.848 17.4643 162.379 17.2691 161.894 17.2803C161.401 17.2741 160.927 17.4676 160.579 17.8169C160.231 18.1663 160.038 18.6418 160.045 19.1357Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default AppStoreSvgImg;
