// Breadcrumb cases
export const BREADCRUMB = "BREADCRUMB";

// CTA Steps
export const COUNTRY = "Country";
export const STUDY_STAGE = "study-stage";
export const COLLEGE_OR_GRADE = "college-or-grade";

// Explore event Category types
export const COUNTRY_TYPE = "country";
export const STAGE_TYPE = "stage";
export const UNIVERSITY_TYPE = "university";
export const COLLEGE_TYPE = "college";
export const GRADE_TYPE = "grade";
export const DEPARTMENT_TYPE = "department";

// Explore event trigger types
export const EXPLORE_BUTTON = "Explore button";
export const HOMEPAGE_POPUP = "Breadcrumb change";
export const LANDING_CTA = "LandingPage selection bar";
export const HOMEPAGE_SHOWALL = "Show all button";
