import { COUNTRY } from "@Constants/Breadcrumb";
import BreadcrumbSlashSvgIcon from "public/svgs/icons/global-icons/BreadcrumbSlashSvgIcon";
import { ReactElement, useEffect, useRef, useState } from "react";
import { CategoriesType } from "types/categories";
import { Wrapper } from "./CurrentBreadcrumb.styled";

interface Props {
  breadcrumb: CategoriesType[];
  fontSize: string;
  parentElementID?: string;
}

// INPUT: object {name, short_name, type} && OUTPUT: flag/shortname || .../last-name-should-be-full-name
export default function CurrentBreadcrumb({ breadcrumb, fontSize, parentElementID }: Props): ReactElement {
  let textLength = useRef(null);
  const last = breadcrumb.length - 1;
  const [percentage, setPercentage] = useState(0);

  const addSlashToItem = (item, index) => (
    <Wrapper key={index} fontSize={fontSize}>
      {item}
      {index !== last && <BreadcrumbSlashSvgIcon margin="0 8px" />}
    </Wrapper>
  );

  useEffect(() => {
    setPercentage(
      (textLength?.current?.offsetWidth /
        (parentElementID ? document.getElementById(parentElementID)?.offsetWidth : window.innerWidth)) *
        100
    );
  }, [breadcrumb]);

  return (
    <>
      <Wrapper fontSize={fontSize} ref={textLength}>
        {breadcrumb.map((item, index) =>
          addSlashToItem(
            item.type === COUNTRY
              ? `${item.emoji}`
              : index === last
                ? `${item.name}`
                : percentage > 80
                  ? `...`
                  : percentage > 65
                    ? `${item.short_name}`
                    : `${item.name}`,
            index
          )
        )}
      </Wrapper>
    </>
  );
}
