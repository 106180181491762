// import { CommonButton } from "@Components/common/CommonButton";
import { CommonButton } from "@Components/common/CommonButton";
import { mq } from "@Utils/StyleUtil";
import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.seagullNeutrals};
  position: relative;
  top: -28px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 64px;
  gap: 20px;
  ${mq(768)} {
    min-height: 74px;
  }
`;

export const IconWrapper = styled.div`
  width: 57px;
  height: 32px;
  svg {
    width: 100%;
    height: 100%;
  }
  ${mq(992)} {
    width: 45px;
    height: 25px;
  }
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.fontsizes.body14};
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  color: ${({ theme }) => theme.colors.nero};
  ${mq(768)} {
    font-size: ${({ theme }) => theme.fontsizes.body12};
  }
`;

export const Btn = styled(CommonButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 9px;
  background-color: transparent;
  box-shadow: none;
  border: 1px solid #019ade;
  color: #019ade;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontsizes.body16};
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  cursor: pointer;
  &:hover {
    background-color: transparent;
  }
  ${mq(768)} {
    font-size: ${({ theme }) => theme.fontsizes.body12};
    height: 34px;
  }
  ${mq(576)} {
    font-size: ${({ theme }) => theme.fontsizes.body14};
  }
`;

// export const
