import { Fragment } from "react";
import Countdown from "react-countdown";
import { CountBox, CounterWrapper, NavbarBannerWrapper } from "../Navbar.styled";

export default function NavbarBanner({
  onCountDownComplete,
  endDate,
}: {
  onCountDownComplete: () => void;
  endDate: string;
}) {
  return (
    <NavbarBannerWrapper dir="rtl">
      <span>بمناسبة يوم التأسيس احصل على خصم 22% على جميع المواد 🔥 استخدم كود SA22 🔥</span>
      <Countdown
        date={endDate}
        onComplete={onCountDownComplete}
        daysInHours={true}
        renderer={({ formatted: { hours, minutes, seconds } }) => {
          return (
            <CounterWrapper dir="ltr">
              <Fragment>
                <CountBox>{hours}</CountBox>
                <span>:</span>
                <CountBox>{minutes}</CountBox>
                <span>:</span>
                <CountBox>{seconds}</CountBox>
              </Fragment>
            </CounterWrapper>
          );
        }}
      />
    </NavbarBannerWrapper>
  );
}
