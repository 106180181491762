import { useRouter } from "next/router";
import { Fragment } from "react";
import { useMediaQuery } from "react-responsive";

type Props = {
  color: string;
};

const BaimsLogoSvg = ({ color }: Props) => {
  const { locale } = useRouter();
  const isDesktop = useMediaQuery({
    minWidth: 1024,
  });

  return (
    <Fragment>
      {locale === "ar" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isDesktop ? "119" : "100"}
          height={isDesktop ? "53" : "44"}
          viewBox="0 0 1016.4 540.8"
        >
          <g transform="translate(-31.8 -41.4)">
            <path
              d="M777.8,582.2c-149.1,0-270.4-121.3-270.4-270.4S628.7,41.4,777.8,41.4s270.4,121.3,270.4,270.4S926.9,582.2,777.8,582.2Zm0-511c-132.7,0-240.7,108-240.7,240.7s108,240.7,240.7,240.7,240.7-108,240.7-240.7S910.5,71.2,777.8,71.2Z"
              fill={color}
            />
            <path
              d="M777.8,499.6c-103.5,0-187.7-84.2-187.7-187.7s84.2-187.7,187.7-187.7,187.7,84.2,187.7,187.7S881.3,499.6,777.8,499.6Zm0-345.8c-87.1,0-158,70.9-158,158s70.9,158,158,158,158-70.9,158-158S864.9,153.8,777.8,153.8Z"
              fill={color}
            />
            <path
              d="M777.8,416.9a105,105,0,1,1,105-105C882.9,369.7,835.7,416.9,777.8,416.9Zm0-180.4a75.3,75.3,0,1,0,75.3,75.3A75.412,75.412,0,0,0,777.8,236.5Z"
              fill={color}
            />
            <path
              d="M59.3,263.3a2.184,2.184,0,0,1,2.5-2.5H84.9a2.184,2.184,0,0,1,2.5,2.5v60.6c0,5,1.9,8.8,5.6,11.4s8.7,4,15,4h4.2q4.95,0,7.5,2.7c1.7,1.8,2.5,4.9,2.5,9.2q0,7.2-2.4,9.9c-1.6,1.8-4.1,2.7-7.6,2.7a60.96,60.96,0,0,1-14.5-1.5,18.466,18.466,0,0,1-10.3-6.7v4.7a109.185,109.185,0,0,1-2.2,23.5,43.079,43.079,0,0,1-7.3,16.8,31.335,31.335,0,0,1-13.5,10.1c-5.6,2.2-12.6,3.4-20.9,3.4H34.3a2.184,2.184,0,0,1-2.5-2.5V391.7a2.184,2.184,0,0,1,2.5-2.5h5.5c4.8,0,8.5-.8,11.2-2.5a14.652,14.652,0,0,0,5.8-7.3A38.075,38.075,0,0,0,59,367.7c.2-4.5.4-9.8.4-15.8l-.1-88.6Z"
              fill={color}
            />
            <path
              d="M137.3,309.2c.2-9.6,1.5-17.8,4.1-24.5a42.54,42.54,0,0,1,10.7-16.4,41.2,41.2,0,0,1,16-9.2,70.982,70.982,0,0,1,40.5.1,42.057,42.057,0,0,1,16.3,9.4,44.281,44.281,0,0,1,10.7,17.1c2.6,7.1,3.8,15.7,3.8,25.8v4.3c0,1.4-.1,2.8-.2,4.1.2,7,1.8,11.9,4.8,14.8a14.979,14.979,0,0,0,10.6,4.3h7.2q4.95,0,7.5,2.7c1.7,1.8,2.5,4.9,2.5,9.2q0,7.2-2.4,9.9c-1.6,1.8-4.1,2.7-7.6,2.7-2.8,0-5.8-.1-8.8-.2a42.273,42.273,0,0,1-9.2-1.6,31.22,31.22,0,0,1-8.7-4,25.345,25.345,0,0,1-7.3-7.3A37.654,37.654,0,0,1,211,361.8a69.79,69.79,0,0,1-22.7,3.5,68.419,68.419,0,0,1-22.4-3.4,36.459,36.459,0,0,1-16.6-11.1,23.394,23.394,0,0,1-6,6.5,27.728,27.728,0,0,1-7.2,3.8,41.255,41.255,0,0,1-7.7,1.9,65.868,65.868,0,0,1-7.5.5h-8.7c-3.3,0-5.8-1-7.5-2.9s-2.5-5.1-2.5-9.6c0-4.8.8-8,2.4-9.7s4.1-2.5,7.6-2.5h9.7c4,0,7.5-1.6,10.7-4.8,3.1-3.2,4.8-9.2,5-17.8a24.218,24.218,0,0,1-.2-3.7A30.208,30.208,0,0,1,137.3,309.2Zm50.9-28.1c-7.9,0-13.8,2.6-17.6,7.7s-5.7,12.6-5.7,22.4q0,15.9,5.7,22.8c3.8,4.6,9.7,7,17.6,7,8.1,0,14.1-2.4,17.9-7.1s5.7-12.3,5.7-22.7C211.8,291.2,203.9,281.1,188.2,281.1Z"
              fill={color}
            />
            <path
              d="M315.4,351.2a18.714,18.714,0,0,1-10.6,9.9c-4.7,1.8-11.1,2.7-19,2.7H262.7q-4.95,0-7.5-2.4c-1.7-1.6-2.5-4.7-2.5-9.3,0-4.8.8-8.1,2.4-10.1,1.6-1.9,4.1-2.9,7.6-2.9h16.1c3,0,5.3-.4,6.8-1.4a9.144,9.144,0,0,0,3.5-4.2,27.087,27.087,0,0,0,1.4-7.5c.2-3.1.2-6.8.2-11.3V263a1.856,1.856,0,0,1,1.4-2,9.291,9.291,0,0,1,2.9-.5h21.4a2.184,2.184,0,0,1,2.5,2.5v60.6c0,5,1.9,8.8,5.6,11.4s8.7,4,15,4h4.2q4.95,0,7.5,2.7c1.7,1.8,2.5,4.9,2.5,9.2q0,7.2-2.4,9.9c-1.6,1.8-4.1,2.7-7.6,2.7a53.4,53.4,0,0,1-16.9-2.4C322,359.9,318.2,356.5,315.4,351.2Z"
              fill={color}
            />
            <path
              d="M287.1,411.5a12.931,12.931,0,0,1-12.9-12.9v-.5a12.931,12.931,0,0,1,12.9-12.9h0A12.931,12.931,0,0,1,300,398.1v.5a12.931,12.931,0,0,1-12.9,12.9Z"
              fill={color}
            />
            <path
              d="M319.3,411.5a12.931,12.931,0,0,1-12.9-12.9v-.5a12.931,12.931,0,0,1,12.9-12.9h0a12.931,12.931,0,0,1,12.9,12.9v.5a12.867,12.867,0,0,1-12.9,12.9Z"
              fill={color}
            />
            <path
              d="M343.7,363.8c-3.3,0-5.8-.6-7.5-1.7-1.7-1.2-2.5-3.5-2.5-7a25.112,25.112,0,0,1,2.4-11.1,7.937,7.937,0,0,1,7.6-4.8h12.2c4.1,0,7.2-1,9.1-2.9s2.9-5.3,2.9-10.3V263.2a2.184,2.184,0,0,1,2.5-2.5h23.1a2.184,2.184,0,0,1,2.5,2.5v69.3c0,10.8-2.2,18.7-6.7,23.7-4.5,5.1-11.9,7.6-22.4,7.6Z"
              fill={color}
            />
            <path
              d="M377.6,411.5a12.931,12.931,0,0,1-12.9-12.9v-.5a12.931,12.931,0,0,1,12.9-12.9h0a12.931,12.931,0,0,1,12.9,12.9v.5a12.931,12.931,0,0,1-12.9,12.9Z"
              fill={color}
            />
            <path
              d="M73.1,236.8a12.931,12.931,0,0,1-12.9-12.9v-.5a12.931,12.931,0,0,1,12.9-12.9h0A12.931,12.931,0,0,1,86,223.4v.5a12.931,12.931,0,0,1-12.9,12.9Z"
              fill={color}
            />
          </g>
        </svg>
      ) : (
        <svg
          width={isDesktop ? "119" : "100"}
          height={isDesktop ? "53" : "44"}
          viewBox="0 0 119 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.0278 52.6953C11.6917 52.6953 0.027832 41.0314 0.027832 26.6953C0.027832 12.3592 11.6917 0.695312 26.0278 0.695312C40.3639 0.695312 52.0278 12.3592 52.0278 26.6953C52.0278 41.0314 40.3654 52.6953 26.0278 52.6953ZM26.0278 3.55778C13.2701 3.55778 2.8903 13.9376 2.8903 26.6953C2.8903 39.4531 13.2701 49.8328 26.0278 49.8328C38.7856 49.8328 49.1654 39.4531 49.1654 26.6953C49.1668 13.9376 38.7871 3.55778 26.0278 3.55778Z"
            fill={color}
          />
          <path
            d="M26.0258 44.7459C16.074 44.7459 7.97705 36.6489 7.97705 26.6957C7.97705 16.7425 16.074 8.64551 26.0272 8.64551C35.9805 8.64551 44.0759 16.7425 44.0759 26.6957C44.0759 36.6489 35.979 44.7459 26.0258 44.7459ZM26.0258 11.508C17.6509 11.508 10.838 18.3208 10.838 26.6957C10.838 35.0706 17.6509 41.8834 26.0258 41.8834C34.4006 41.8834 41.2135 35.0706 41.2135 26.6957C41.2135 18.3208 34.4006 11.508 26.0258 11.508Z"
            fill={color}
          />
          <path
            d="M26.0291 36.7945C20.4602 36.7945 15.9302 32.2645 15.9302 26.6956C15.9302 21.1267 20.4602 16.5967 26.0291 16.5967C31.5979 16.5967 36.128 21.1267 36.128 26.6956C36.128 32.2645 31.5979 36.7945 26.0291 36.7945ZM26.0291 19.4591C22.0386 19.4591 18.7926 22.7051 18.7926 26.6956C18.7926 30.6861 22.0386 33.932 26.0291 33.932C30.0196 33.932 33.2655 30.6861 33.2655 26.6956C33.2655 22.7051 30.0196 19.4591 26.0291 19.4591Z"
            fill={color}
          />
          <path
            d="M62.4495 33.9133V19.5771H67.9366C68.9725 19.5771 69.8345 19.7391 70.5226 20.0631C71.2108 20.3871 71.7265 20.83 72.0698 21.3889C72.4131 21.9492 72.584 22.5838 72.584 23.2927C72.584 23.8768 72.4726 24.3747 72.2481 24.7878C72.0237 25.201 71.7235 25.5354 71.346 25.7925C70.9685 26.0496 70.5449 26.2339 70.0783 26.3454V26.4851C70.5865 26.5134 71.0755 26.6679 71.5452 26.9503C72.0148 27.2327 72.3982 27.631 72.697 28.1437C72.9957 28.6565 73.1443 29.2777 73.1443 30.006C73.1443 30.7476 72.9645 31.4134 72.6048 32.0049C72.2452 32.595 71.7042 33.0602 70.9804 33.402C70.2566 33.7423 69.347 33.9133 68.2502 33.9133H62.4495ZM65.0459 25.6736H67.6156C68.0644 25.6736 68.4672 25.5904 68.8268 25.4254C69.1865 25.2605 69.4704 25.0242 69.677 24.718C69.885 24.4118 69.9891 24.0492 69.9891 23.6301C69.9891 23.0742 69.7944 22.618 69.405 22.2583C69.0156 21.8986 68.4375 21.7188 67.6721 21.7188H65.0474V25.6736H65.0459ZM65.0459 31.7434H67.8385C68.7808 31.7434 69.4615 31.5621 69.8791 31.2009C70.2967 30.8398 70.5048 30.3746 70.5048 29.8038C70.5048 29.3788 70.3993 28.9953 70.1897 28.652C69.9802 28.3087 69.6814 28.0382 69.2935 27.8406C68.9056 27.6429 68.4434 27.5433 67.9069 27.5433H65.0444V31.7434H65.0459Z"
            fill={color}
          />
          <path
            d="M78.3877 33.9127C77.707 33.9127 77.0947 33.7909 76.5507 33.5456C76.0068 33.3004 75.5772 32.9378 75.2622 32.4577C74.9471 31.9777 74.7896 31.3847 74.7896 30.6802C74.7896 30.0738 74.901 29.5715 75.1254 29.1746C75.3498 28.7778 75.6545 28.4613 76.0424 28.222C76.4303 27.9842 76.8673 27.8029 77.3548 27.6795C77.8422 27.5561 78.3476 27.4655 78.8707 27.4105C79.5009 27.3451 80.0121 27.2856 80.4045 27.2321C80.7969 27.1786 81.0822 27.0939 81.2621 26.9795C81.4419 26.865 81.5311 26.6867 81.5311 26.4444V26.4028C81.5311 25.8752 81.375 25.4665 81.0614 25.1782C80.7493 24.8884 80.299 24.7442 79.7104 24.7442C79.0892 24.7442 78.5987 24.8795 78.2361 25.1499C77.8749 25.4204 77.6312 25.74 77.5049 26.1086L75.1388 25.7727C75.3261 25.1187 75.6337 24.5718 76.0632 24.1319C76.4928 23.6905 77.0174 23.359 77.6386 23.1376C78.2599 22.9162 78.945 22.8047 79.6971 22.8047C80.2157 22.8047 80.73 22.8656 81.2442 22.9875C81.7585 23.1094 82.2266 23.3085 82.6517 23.5864C83.0767 23.8644 83.4186 24.2404 83.6772 24.7175C83.9358 25.193 84.0651 25.789 84.0651 26.5024V33.6987H81.6291V32.2214H81.5459C81.3914 32.5201 81.1759 32.7995 80.8979 33.0581C80.62 33.3167 80.2722 33.5248 79.8546 33.6809C79.437 33.8369 78.948 33.9127 78.3877 33.9127ZM79.0461 32.0505C79.5544 32.0505 79.9958 31.9494 80.3688 31.7458C80.7419 31.5422 81.0302 31.2732 81.2338 30.9373C81.4374 30.6014 81.5385 30.2343 81.5385 29.839V28.5712C81.4597 28.6366 81.3245 28.6976 81.1357 28.7526C80.947 28.809 80.7359 28.8581 80.5026 28.8997C80.2692 28.9413 80.0389 28.9785 79.81 29.0112C79.5811 29.0439 79.3835 29.0721 79.2155 29.0944C78.838 29.1464 78.4992 29.2296 78.2004 29.347C77.9017 29.4645 77.6654 29.6265 77.493 29.833C77.3206 30.0411 77.2344 30.3086 77.2344 30.6341C77.2344 31.1008 77.4053 31.453 77.7456 31.6908C78.0845 31.9316 78.5185 32.0505 79.0461 32.0505Z"
            fill={color}
          />
          <path
            d="M87.892 22.2432C87.4863 22.2432 87.14 22.1094 86.8531 21.8404C86.5663 21.5714 86.4221 21.2489 86.4221 20.8714C86.4221 20.4894 86.5663 20.164 86.8561 19.895C87.1459 19.6259 87.4907 19.4922 87.892 19.4922C88.2933 19.4922 88.6381 19.6259 88.9249 19.895C89.2118 20.164 89.3559 20.4894 89.3559 20.8714C89.3559 21.2489 89.2118 21.5729 88.9249 21.8404C88.6381 22.1094 88.2933 22.2432 87.892 22.2432ZM86.6183 33.913V23.1617H89.1523V33.913H86.6183Z"
            fill={color}
          />
          <path
            d="M91.7578 33.913V23.1617H94.1804V24.9883H94.3067C94.5311 24.373 94.9012 23.89 95.4199 23.5422C95.9371 23.1944 96.5568 23.0205 97.2747 23.0205C98.0029 23.0205 98.6182 23.1974 99.1191 23.5496C99.6199 23.9018 99.9737 24.3819 100.18 24.9883H100.292C100.53 24.3908 100.932 23.9137 101.5 23.557C102.068 23.2003 102.74 23.022 103.52 23.022C104.51 23.022 105.317 23.3341 105.942 23.9598C106.568 24.5855 106.88 25.498 106.88 26.6974V33.9145H104.339V27.0898C104.339 26.4225 104.162 25.9335 103.807 25.6229C103.451 25.3123 103.017 25.1577 102.505 25.1577C101.894 25.1577 101.417 25.3479 101.073 25.7284C100.73 26.1089 100.559 26.6023 100.559 27.2087V33.9145H98.0743V26.9843C98.0743 26.4284 97.9078 25.9855 97.5734 25.6541C97.239 25.3227 96.805 25.1577 96.2685 25.1577C95.9044 25.1577 95.5729 25.2498 95.2742 25.4341C94.9755 25.6184 94.7377 25.877 94.5608 26.2114C94.384 26.5458 94.2948 26.9337 94.2948 27.3766V33.9145L91.7578 33.913Z"
            fill={color}
          />
          <path
            d="M117.951 25.7919L115.641 26.0446C115.576 25.8112 115.463 25.5928 115.302 25.3862C115.142 25.1811 114.925 25.0146 114.654 24.8898C114.384 24.7634 114.052 24.701 113.66 24.701C113.132 24.701 112.691 24.8155 112.334 25.0443C111.977 25.2732 111.801 25.569 111.805 25.9331C111.801 26.2452 111.915 26.5008 112.151 26.6955C112.386 26.8917 112.777 27.0522 113.324 27.1786L115.158 27.5709C116.175 27.7909 116.933 28.1387 117.429 28.6142C117.925 29.0898 118.177 29.7126 118.181 30.4839C118.177 31.1602 117.979 31.7561 117.589 32.2719C117.2 32.7876 116.659 33.1903 115.969 33.4787C115.28 33.767 114.486 33.9126 113.59 33.9126C112.275 33.9126 111.215 33.6362 110.412 33.0833C109.61 32.5304 109.131 31.7591 108.977 30.7693L111.448 30.5315C111.56 31.0175 111.798 31.3831 112.162 31.6298C112.526 31.8765 113 32.0014 113.583 32.0014C114.185 32.0014 114.669 31.878 115.035 31.6298C115.4 31.3831 115.585 31.0769 115.585 30.7128C115.585 30.4052 115.467 30.151 115.231 29.9504C114.995 29.7497 114.63 29.5952 114.135 29.4881L112.301 29.1032C111.27 28.8892 110.508 28.5251 110.013 28.0153C109.518 27.504 109.273 26.8561 109.277 26.0728C109.273 25.41 109.454 24.8348 109.819 24.3473C110.185 23.8598 110.696 23.4823 111.353 23.2133C112.009 22.9443 112.767 22.8105 113.624 22.8105C114.885 22.8105 115.877 23.0796 116.603 23.6161C117.328 24.1526 117.777 24.8779 117.951 25.7919Z"
            fill={color}
          />
        </svg>
      )}
    </Fragment>
  );
};

export default BaimsLogoSvg;
