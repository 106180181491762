import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #45aaf2;
  direction: rtl;
  position: relative;
  top: -28px;
`;

export const InnerContainer = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin: 0 auto;
  display: flex;
  max-height: 48px;
  min-height: 48px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: white;
`;

export const Text = styled.div`
  font-size: 12px;
  line-height: 16px;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: normal;
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 2px;
`;

export const TimeBox = styled.div`
  font-weight: 600;
  display: flex;
  max-height: 32px;
  min-height: 32px;
  min-width: 32px;
  max-width: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #225579;
  color: white;
`;
