import { CommonButton } from "@Components/common/CommonButton";
import { NavbarDropdown } from "@Components/Navbar/Navbar.styled";
import { grid } from "@Style/grid";
import { rgba, stripButton } from "@Utils/StyleUtil";
import styled, { css } from "styled-components";

export const HeroAreaContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 1;
  background: ${({ theme }) => theme.colors.nero};
`;

export const InfoContainer = styled.div`
  z-index: 1;
  color: ${({ theme }) => theme.colors.white};
  margin: auto;
  text-align: center;
  @media only screen and (max-width: ${grid.breakpoints.md}px) {
    width: 100%;
    bottom: 0;
    transform: 0;
  }
`;

export const InfoContentContainer = styled.div<{ readonly isBannerVisible?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  left: 0;
  transform: translate(0%, 25%);
  bottom: 0;
  @media only screen and (max-width: ${grid.breakpoints.md}px) {
    width: 90%;
    margin: auto;
    bottom: 0;
    transform: 0;
    top: ${({ isBannerVisible }) => (isBannerVisible ? "6%" : "-9%")};
  }
`;

export const HeadLine = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: ${({ theme }) => theme.fontsizes.h1};
  line-height: 104px;
  @media only screen and (max-width: ${grid.breakpoints.md}px) {
    font-size: ${({ theme }) => theme.fontsizes.h4};
    line-height: 44px;
  }
`;

export const Paragraph = styled.p<{ readonly isBannerVisible?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  line-height: 32px;
  padding: 0 1rem;
  margin: 40px auto;
  max-width: 780px;
  @media only screen and (max-width: ${grid.breakpoints.md}px) {
    padding: 0 2rem;
    line-height: 24px;
    font-size: ${({ theme }) => theme.fontsizes.body16};
    ${({ isBannerVisible }) =>
      isBannerVisible &&
      css`
        margin: 0 auto 16px auto;
      `}
  }
`;

export const Overlay = styled.div`
  position: absolute;
  background: ${({ theme }) => rgba(theme.colors.black, 0.3)};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const ButtonContainer = styled.div<{
  readonly padding?: string;
}>`
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
`;

export const CTAButtonBoxContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-column-gap: 0px;
`;

export const DropdownContainer = styled.div`
  position: relative;
  padding-left: 38.5px;
  height: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const DropdownWrapper = styled.button<{
  readonly isOpen?: boolean;
}>`
  ${stripButton};
  display: block;
  align-items: center;
  text-align: left;
  line-height: 1.5;
  padding: 2px 0;
  width: 90%;
  height: 100%;
  ${({ isOpen }) => isOpen && css``}
`;

export const DropdownTitle = styled.span<{
  readonly color?: string;
  readonly fontSize?: string;
  readonly width?: string;
}>`
  color: ${({ theme, color }) => theme.colors[color]};
  font-size: ${({ theme, fontSize }) => theme.fontsizes[fontSize]};
  font-weight: ${({ theme }) => theme.fontWeight.font500};
  margin-bottom: 3px;
  display: inline-block;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    `}

  @media only screen and (max-width: ${grid.breakpoints.lg}px) {
    font-size: ${({ theme, color }) => (color === "gray2" ? theme.fontsizes.body14 : theme.fontsizes.body18)};
  }
`;

export const CTAContainer = styled.div`
  background: ${({ theme }) => theme.colors.gray6};
  border-radius: 80px;
  height: 88px;
  text-align: left;
  width: 780px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr) 2fr;
  grid-column-gap: 0px;
  @media only screen and (max-width: ${grid.breakpoints.lg}px) {
    width: 735px;
  }
  & > ${DropdownContainer} {
    &:after {
      content: " ";
      position: absolute;
      border-right: 1px solid ${({ theme }) => theme.colors.gray5};
      right: 0;
      top: 0;
      bottom: 0;
      height: 70%;
      margin-top: auto;
      margin-bottom: auto;
      &:last-child {
        border-right: 0;
      }
    }
    &:first-child {
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
      padding-left: 63px;
    }
  }
  & ${DropdownTitle} {
    &:last-child {
      margin-bottom: 0;
      font-weight: ${({ theme }) => theme.fontWeight.font400};
    }
  }
  ${CommonButton} {
    height: 68px;
    margin: auto;
    font-size: ${({ theme }) => theme.fontsizes.body18};
    font-weight: ${({ theme }) => theme.fontWeight.font400};
    padding: 0 27.5px;
    box-shadow: none;
    @media only screen and (max-width: ${grid.breakpoints.lg}px) {
      margin-right: 10px;
    }
  }
`;

export const CTAHolder = styled.div`
  @media only screen and (max-width: ${grid.breakpoints.md}px) {
    display: none;
  }
`;

export const CTAMobileHolder = styled.div`
  display: none;
  @media only screen and (max-width: ${grid.breakpoints.md}px) {
    display: block;
  }
`;

export const SearchIcon = styled.img`
  margin-right: 10px;
  @media only screen and (max-width: ${grid.breakpoints.lg}px) {
    margin-right: 0px;
  }
`;

export const DropdownHandler = styled(NavbarDropdown)`
  width: 202px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 25px rgb(0 0 0 / 8%);
  padding: 8px 8px;
  top: 60%;
  max-height: 24vh;
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cacaca;
    border-right: none;
    border-left: none;
    border-radius: 9px;
  }
  visibility: inherit;
`;

export const HeroErrorContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray1};
  font-size: ${({ theme }) => theme.fontsizes.body18};
  border-radius: 40px;
  width: fit-content;
  margin: 12px auto;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
`;

export const HeroErrorContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: center;
  justify-content: center;
`;

export const HeroErroMessage = styled.span``;

export const HeroDropdownBlockerOverlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 9;
  cursor: pointer;
  background: transparent;
`;

export const HeroDropdownListItem = styled.li`
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.gray5_old};
  }
`;

export const HeroDropdwon = styled.ul`
  color: ${({ theme }) => theme.colors.gray1};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  & > ${HeroDropdownListItem} {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const CTAMobileContainer = styled.div`
  background: ${({ theme }) => theme.colors.gray6};
  padding: 16px;
  border-radius: 12px;
`;

export const CTAMobileLi = styled.li<{
  readonly variant: "default" | "empty-bg";
}>`
  background: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  color: ${({ theme }) => theme.colors.nero};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 16px 16px 16px 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
  ${({ variant }) =>
    variant === "empty-bg" &&
    css`
      background: none;
      padding: 0;
      & > button {
        margin-top: 4px;
        font-size: ${({ theme }) => theme.fontsizes.body18};
        width: 100%;
      }
    `};
`;

export const CTAMobileLiContent = styled.div`
  display: flex;
  align-items: center;
  font-size: inherit;
  font-weight: 400;
`;

export const CTAMobileUl = styled.ul`
  border-bottom: 12px;
`;

export const BreadcrumbIconHolder = styled.img<{
  readonly marginRight?: string;
}>`
  margin-right: ${({ marginRight }) => marginRight || 0};
  &:lang(ar) {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
`;

export const CTAMobileLayer = styled.div`
  position: fixed;
  background: ${({ theme }) => theme.colors.white};
  inset: 0;
  z-index: 10;
  display: none;
  color: ${({ theme }) => theme.colors.gray1};
  @media only screen and (max-width: ${grid.breakpoints.md}px) {
    display: block;
  }
`;

export const CTAMobileLayerContainer = styled.div`
  display: grid;
  padding: 24px;
  height: 100%;
`;

export const CTAMobileLayerButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  & > ${CommonButton} {
    width: 100%;
    font-size: ${({ theme }) => theme.fontsizes.body18};
  }
`;

export const CTAMobileLayerFooterButtonHolder = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: auto;
  height: 80px;

  & > ${CommonButton} {
    width: 100%;
    height: 56px;
    font-size: ${({ theme }) => theme.fontsizes.body18};
  }
`;

export const CTAMobileLayerHeader = styled.div`
  text-align: center;
`;

export const CTAMobileItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 15px 0 0;
`;
export const CTAMobileItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.gray6};
  border-radius: 10.0585px;
  padding: 0 16px;
  max-height: 56px;
  min-height: 56px;
`;
export const CTAMobileItem = styled.div<{
  readonly variant: "default" | "fit-content";
}>`
  flex: 1;
  display: flex;
  gap: 12px;
  font-size: ${({ theme }) => theme.fontsizes.body18};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  line-height: 32px;
  border-radius: 10.0585px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-height: 56px;
  min-height: 56px;
  background: ${({ theme }) => theme.colors.gray6};
  ${({ variant }) =>
    variant === "fit-content" &&
    css`
      height: fit-content;
      min-height: 56px;
    `};
`;

export const ArrowDirectionWrapper = styled.div<{
  readonly vertical?: boolean;
  readonly isOpened?: boolean;
  readonly inProfilePage?: boolean;
}>`
  ${({ vertical, isOpened }) =>
    vertical
      ? css`
          transform: ${isOpened ? "rotate(180deg)" : " rotate(0deg)"};
        `
      : css`
          & > :lang(ar) {
            transform: rotate(180deg);
          }
        `}

  ${({ inProfilePage }) => inProfilePage && "align-self: center;"}
`;

export const CTAMobileItemImageHolder = styled.img`
  width: 40px;
`;

export const CTAMobileArrow = styled.img`
  &:lang(ar) {
    transform: scale(-1, 1);
  }
`;

export const CTAMobileItemIconHolder = styled.span`
  width: 30px;
  font-size: ${({ theme }) => theme.fontsizes.h5};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CTAMobileLayerHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

export const CTAMobileLayerHeaderHeadline = styled.h1`
  font-size: ${({ theme }) => theme.fontsizes.h5};
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  line-height: 40px;
  color: ${({ theme }) => theme.colors.nero};
  padding: 0 10px;
`;

export const CTAMobileLayerHeaderMessage = styled.p`
  font-size: ${({ theme }) => theme.fontsizes.body16};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray2};
  margin-top: 12px;
  margin-bottom: 26px;
`;

export const CTAMobileLayerImg = styled.img`
  &:lang(ar) {
    transform: scale(-1, 1);
  }
`;
