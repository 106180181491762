import {
  COLLEGE_TYPE,
  COUNTRY_TYPE,
  DEPARTMENT_TYPE,
  GRADE_TYPE,
  STAGE_TYPE,
  UNIVERSITY_TYPE,
} from "@Constants/Breadcrumb";
import { __explore, __search } from "@repo/analytics";
import { _GetBreadCrumbs } from "@Services/Home";
import { SearchProps } from "types/AlgoliaSearch";
import { BreadCrumbs } from "types/BreadCrumbs";
import { UserProfile } from "types/users";

export const filterCategoriesByType = (data: BreadCrumbs[], catType: string) => {
  const category = data?.filter((cat: BreadCrumbs) => cat.type === catType);
  if (category?.length > 1) {
    return category.map((item) => item.name).join("/");
  } else {
    return category[0]?.name;
  }
};

export const ExploreEvent = async (CategorySlug: string, triggerScoure: string, userData?: UserProfile) => {
  try {
    const response = await _GetBreadCrumbs(CategorySlug);
    __explore(
      {
        country_selected: filterCategoriesByType(response?.data, COUNTRY_TYPE),
        stage_selected: filterCategoriesByType(response?.data, STAGE_TYPE),
        university_selected: filterCategoriesByType(response?.data, UNIVERSITY_TYPE),
        college_selected: filterCategoriesByType(response?.data, COLLEGE_TYPE),
        grade_selected: filterCategoriesByType(response?.data, GRADE_TYPE),
        department_selected: filterCategoriesByType(response?.data, DEPARTMENT_TYPE),
        trigger_source: triggerScoure,
      },
      userData
    );
  } catch (error) {
    console.log("Error in explore analytics", error);
  }
};

export const SearchEvent = async (searchProps: SearchProps, userData?: UserProfile) => {
  try {
    __search(searchProps, userData);
  } catch (error) {
    console.log("Error in search analytics", error);
  }
};
