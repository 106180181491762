import { useAuth } from "@Context/Auth.context";
import useTranslation from "next-translate/useTranslation";
import { Dispatch, Fragment, ReactElement, SetStateAction, useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";
import { LanguageWrapper, NavbarDropdown, ProfileBtn, ProfileDropdownLink } from "../Navbar.styled";

interface Props {
  setShowBlurredBg: Dispatch<SetStateAction<boolean>>;
}

export default function ProfileButton({ setShowBlurredBg }: Props): ReactElement {
  const { t } = useTranslation("common");
  const { t: tProfile } = useTranslation("Profile");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { userData, logOut } = useAuth();
  const [imgSrc, setImgSrc] = useState<null | string>(userData.profile_picture.url);

  useClickAway(dropdownRef, () => {
    setIsDropdownOpen(false);
  });

  useEffect(() => {
    setShowBlurredBg(isDropdownOpen);
  }, [isDropdownOpen]);

  return (
    <LanguageWrapper ref={dropdownRef}>
      <ProfileBtn type="button" onClick={() => setIsDropdownOpen((prev) => !prev)}>
        {imgSrc === null ? (
          <div>{userData.name === null ? "B" : userData?.name[0]}</div>
        ) : (
          <img src={imgSrc} alt={userData?.name} onError={() => setImgSrc(null)} />
        )}
      </ProfileBtn>
      <NavbarDropdown isOpen={isDropdownOpen} position="right">
        {/* Hide Un-used links */}
        {/* <li>
          <ProfileDropdownLink href="/account">{t("account")}</ProfileDropdownLink>
        </li>
        <li>
          <ProfileDropdownLink href="/account/notifications">{t("notifications")}</ProfileDropdownLink>
        </li> */}
        {(userData?.roles?.includes("admin") ||
          userData?.roles?.includes("instructor") ||
          userData?.roles?.includes("pro_instructor")) && (
          <li>
            <ProfileDropdownLink
              href={
                userData?.roles?.includes("admin")
                  ? `${process.env.NEXT_PUBLIC_TEACHER_PORTAL_URL}`
                  : `${process.env.NEXT_PUBLIC_TEACHER_PORTAL_URL}/${userData?.id}/profile`
              }
            >
              {t("dash_board")}
            </ProfileDropdownLink>
          </li>
        )}
        {(userData?.roles?.includes("instructor") || userData?.roles?.includes("pro_instructor")) && (
          <>
            <li>
              <ProfileDropdownLink href={`/${userData.id}`}>{t("profile")}</ProfileDropdownLink>
            </li>
            {/* <li>
              <ProfileDropdownLink href={`${process.env.NEXT_PUBLIC_URL_BAIMS}/instructors/bill`}>
                {t("receipts")}
              </ProfileDropdownLink>
            </li> */}
          </>
        )}
        {(userData?.roles?.includes("student") || userData?.roles.some((role) => role.includes("ambassador"))) && (
          <Fragment>
            <li>
              <ProfileDropdownLink href={`/profile`}>{t("profile")}</ProfileDropdownLink>
            </li>
            <li>
              <ProfileDropdownLink href={`/profile`}>{t("subscriptions")}</ProfileDropdownLink>
            </li>
          </Fragment>
        )}
        {/* Hide until confirm to release the ambassador page
        {userData?.roles.some((role) => role.includes("ambassador")) && (
          <li>
            <ProfileDropdownLink href={"/profile/ambassador-performance"}>
              {tProfile("ambassador-performance")}
            </ProfileDropdownLink>
          </li>
        )}
         */}
        {/* {userData?.roles?.includes("ambassador-cash-reward") && (
          <li>
            <ProfileDropdownLink href={`${process.env.NEXT_PUBLIC_URL_BAIMS}/profile/ambassador`}>
              {t("baims-ambassador")}
            </ProfileDropdownLink>
          </li>
        )}
        {userData?.roles?.includes("ambassador") && (
          <>
            <li>
              <ProfileDropdownLink href={`${process.env.NEXT_PUBLIC_URL_BAIMS}/profile/ambassador`}>
                {t("baims-ambassador")}
              </ProfileDropdownLink>
            </li>
            <li>
              <ProfileDropdownLink href={`${process.env.NEXT_PUBLIC_URL_BAIMS}/profile/leaderboard`}>
                {t("baims-leaderboard")}
              </ProfileDropdownLink>
            </li>
          </>
        )} */}
        <li>
          <ProfileDropdownLink as="button" onClick={() => [setShowBlurredBg(false), logOut()]}>
            {t("sign-out")}
          </ProfileDropdownLink>
        </li>
      </NavbarDropdown>
    </LanguageWrapper>
  );
}
