import { COOKIE_LANG } from "@Constants/index";
import { configureAxiosLocale } from "lib/Axios";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import GlobeSvgIcon from "public/svgs/icons/global-icons/GlobeSvgIcon";
import NavDropdownSvgIcon from "public/svgs/icons/global-icons/NavDropdownSvgIcon";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useClickAway, useCookie, useMedia } from "react-use";
import { NavbarVariant } from "types/common";
import { DropdownLang, DropdownWrapper, LanguageWrapper, NavbarDropdown, SelectedLanguage } from "../Navbar.styled";
import { __languageChanged } from "@repo/analytics";
import { useAuth } from "@Context/Auth.context";

interface Props {
  setShowBlurredBg: Dispatch<SetStateAction<boolean>>;
  navbarType: NavbarVariant;
}

export default function NavbarLangSwitch({ setShowBlurredBg, navbarType }: Props) {
  const { t } = useTranslation("common");
  const { locales, locale, asPath, route } = useRouter();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [, updateCookieLang] = useCookie(COOKIE_LANG);
  const [, updateNewCookieLang] = useCookie("Next-Locale");
  const dropdownRef = useRef(null);
  const isDesktop = useMedia("(min-width: 992px)");
  const isMobile = useMedia("(max-width: 575.98px)");
  const { userData } = useAuth();
  const switchLang = (lang) => {
    configureAxiosLocale(lang);
    updateCookieLang(lang);
    updateNewCookieLang(lang); // For Next 14 locale
    __languageChanged(lang as "en" | "ar", userData);
  };

  useClickAway(dropdownRef, () => {
    setIsDropdownOpen(false);
    if (window && window.fullpage_api) {
      window.fullpage_api.setAllowScrolling(true);
    }
  });
  useEffect(() => {
    setIsDropdownOpen(false);
  }, [locale]);

  useEffect(() => {
    setShowBlurredBg(isDropdownOpen);
    if (window && window.fullpage_api) {
      window.fullpage_api.setAllowScrolling(!isDropdownOpen);
    }
  }, [isDropdownOpen]);

  return (
    <LanguageWrapper ref={dropdownRef}>
      <DropdownWrapper type="button" isOpen={isDropdownOpen} onClick={() => setIsDropdownOpen((prev) => !prev)}>
        {navbarType === "white-bg" || isMobile ? <GlobeSvgIcon /> : <GlobeSvgIcon color="white" />}
        {isDesktop && <SelectedLanguage>{locale?.toUpperCase()}</SelectedLanguage>}
        {isMobile && <SelectedLanguage>{t(locale)}</SelectedLanguage>}
        {navbarType === "white-bg" || isMobile ? (
          <NavDropdownSvgIcon isOpen={isDropdownOpen} />
        ) : (
          <NavDropdownSvgIcon isOpen={isDropdownOpen} color="white" />
        )}
      </DropdownWrapper>
      <NavbarDropdown isOpen={isDropdownOpen}>
        {locales?.map((lng) => (
          <li key={"Switch-Lang-" + lng}>
            <DropdownLang
              isSelected={locale === lng}
              className={lng === "ar" ? "ar" : ""}
              onClick={(e) =>
                lng !== locale ? switchLang(lng) : [e.preventDefault(), setIsDropdownOpen((prev) => !prev)]
              }
              href={asPath}
              locale={lng !== locale ? lng : locale}
            >
              {t(lng)}
            </DropdownLang>
          </li>
        ))}
      </NavbarDropdown>
    </LanguageWrapper>
  );
}
