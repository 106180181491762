function asLiterals<T extends string>(arr: T[]): T[] {
  return arr;
}
export const AlgoliaKeys = asLiterals(["instructors", "subjects", "live-sessions", "packages"]);

// Search event types
export const SUBJECT_TYPE = "subject";
export const TEACHER_TYPE = "teacher";
export const LIVE_TYPE = "live";
export const PACKAGE_TYPE = "package";
