const BoubyanBankLogoSvg = () => {
  return (
    <svg width="110" height="50" viewBox="0 0 160 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M133.624 24.5343H130.859V29.6288C130.859 30.4357 130.409 30.8903 129.627 30.8903H107.195V24.8047C107.001 24.7495 106.814 24.6971 106.628 24.6487C104.649 24.1389 103.611 24.1188 103.262 24.1188C102.34 24.1188 101.089 24.2842 100.242 24.7912C99.5109 25.2067 98.8968 25.7997 98.4565 26.5154C98.0306 27.2333 97.8146 28.0561 97.8331 28.8904C97.8265 29.5563 97.9657 30.2155 98.2411 30.8218C98.5165 31.4282 98.9213 31.9671 99.4273 32.4006C99.4744 32.441 100.534 33.4859 102.443 33.5734H104.546C104.277 34.7138 103.352 35.3849 102.132 35.3849H100.336L101.481 37.9227H102.131C103.35 37.9219 104.528 37.4813 105.448 36.6819C106.368 35.8825 106.968 34.7782 107.138 33.572L107.965 33.5828H129.894C130.26 33.5848 130.625 33.5455 130.983 33.4658L131.085 33.4416C133.219 32.8942 133.592 30.8163 133.624 29.6274C133.624 29.5562 133.624 29.493 133.624 29.4271V24.5343ZM104.474 30.8674L104.394 30.9145C104.016 31.1364 103.519 31.248 102.913 31.248C102.127 31.248 101.529 31.0087 101.081 30.5164C100.703 30.0428 100.509 29.4495 100.533 28.8447C100.506 28.1454 100.716 27.5644 101.155 27.1233C101.635 26.6283 102.278 26.3863 103.119 26.3863C103.527 26.3848 103.933 26.4299 104.331 26.5208L104.475 26.5544L104.474 30.8674Z"
        fill="#323232"
      />
      <path
        d="M31.5202 24.5034C31.9939 24.4818 32.4611 24.3851 32.9043 24.2169V22.0651H30.1442V23.3952C30.1442 24.143 30.5939 24.5034 31.5202 24.5034Z"
        fill="#323232"
      />
      <path
        d="M92.5606 29.6449C92.5606 30.4209 92.1082 30.8849 91.3488 30.8849H70.4312C69.2113 30.8849 68.8599 30.203 68.8707 28.8837V24.5343H66.1442V29.2818C66.1442 30.3981 65.6218 30.9185 64.5016 30.9185H43.2676C42.5176 30.9185 42.0881 30.4653 42.0881 29.6785V20.4338H39.3953V29.4795C39.3953 32.1141 40.7 33.5639 43.067 33.5639H64.6605C65.7901 33.5639 66.7932 33.108 67.3439 32.3455L67.4866 32.1478L67.6428 32.3347C68.316 33.1416 69.2692 33.5639 70.4083 33.5639H91.6154C94.9289 33.5639 95.2871 30.7074 95.2871 29.4795V24.5343H92.5606V29.6449Z"
        fill="#323232"
      />
      <path
        d="M34.1336 33.3084C34.1354 33.3382 34.139 33.3678 34.1444 33.3972C34.1585 33.4821 34.1666 33.5679 34.1686 33.654V33.7885L34.0474 33.8356C33.0861 34.2122 32.1975 34.4031 31.4071 34.4031C29.6218 34.4031 28.6793 33.5236 28.6793 31.86V27.138H26V32.3387C26 35.2383 27.7571 36.6424 31.3721 36.6424C32.7939 36.6424 34.5631 36.2752 36.7779 35.5221V27.138H34.1336V33.3084Z"
        fill="#323232"
      />
      <path
        d="M59.8821 36.8199C59.8821 37.5623 60.3318 37.9227 61.2568 37.9227C61.7305 37.9011 62.1977 37.8044 62.6409 37.6363V35.4844H59.8821V36.8199Z"
        fill="#323232"
      />
      <path
        d="M64.5811 36.8199C64.5811 37.5623 65.0308 37.9227 65.9558 37.9227C66.4276 37.9006 66.8929 37.8039 67.3345 37.6363V35.4844H64.5811V36.8199Z"
        fill="#323232"
      />
      <path
        d="M89.8086 36.8199C89.8086 37.5623 90.2583 37.9227 91.1832 37.9227C91.6569 37.9011 92.1241 37.8044 92.5673 37.6363V35.4844H89.8086V36.8199Z"
        fill="#323232"
      />
      <path
        d="M126.109 36.8199C126.109 37.5623 126.559 37.9227 127.484 37.9227C127.956 37.9009 128.423 37.8042 128.865 37.6363V35.4844H126.109V36.8199Z"
        fill="#323232"
      />
      <path
        d="M75.2069 0.0573378C73.1106 0.176494 71.0221 0.406347 68.9502 0.745925C68.9344 0.74753 68.92 0.755302 68.91 0.767534C68.9001 0.779767 68.8954 0.795456 68.897 0.81115C68.8986 0.826844 68.9064 0.841258 68.9186 0.85122C68.9309 0.861183 68.9466 0.865877 68.9623 0.864272C77.3382 1.10501 84.664 5.65477 88.9496 12.4411C89.8963 13.9375 91.2068 15.1702 92.7589 16.0245C94.311 16.8788 96.0542 17.3269 97.8264 17.3271L100.888 17.3661H111.478C105.547 7.03059 91.532 -0.748251 75.2069 0.0573378Z"
        fill="#C8102E"
      />
      <path
        d="M48.1537 17.2988H54.9099C56.212 17.2962 57.4863 16.9227 58.5833 16.2221C59.6803 15.5215 60.5545 14.5229 61.1033 13.3435C61.5043 12.4764 61.991 11.6515 62.5561 10.881C64.2411 8.71827 66.5581 7.13287 69.1858 6.3447C70.4361 6.0003 71.7324 5.8515 73.0284 5.90358L73.0378 5.84306C71.009 5.14419 68.8787 4.78475 66.7326 4.77925C58.455 4.76446 51.3123 9.92077 48.1537 17.2988Z"
        fill="#C8102E"
      />
      <path
        d="M35.5285 49.6959V49.6381C36.369 49.291 37.0936 48.7127 37.6181 47.9704C38.1487 47.2472 38.4341 46.3736 38.4327 45.477C38.4389 41.8314 35.9553 40.0037 30.9817 39.9939C29.6945 39.9939 28.0627 39.971 26.0431 40.2386L26 59.6535C26.8886 59.8068 30.1227 59.9736 30.8794 59.9749C36.3664 59.9857 39.1135 58.1109 39.1207 54.3506C39.1488 53.2765 38.8171 52.2238 38.1782 51.3595C37.5472 50.5167 36.6639 49.9622 35.5285 49.6959ZM29.5828 43.192C29.8664 43.1544 30.1357 43.1216 30.3906 43.0938C30.6405 43.0659 30.8918 43.0524 31.1432 43.0535C32.259 43.0535 33.1005 43.188 33.6677 43.457C34.4226 43.855 34.7996 44.5643 34.7987 45.5846C34.7987 47.324 33.6318 48.191 31.2981 48.1856H29.568L29.5828 43.192ZM31.1446 56.8574C30.8882 56.8569 30.6319 56.842 30.3771 56.8131C30.1133 56.7853 29.839 56.7516 29.5545 56.7122L29.5666 51.2425H31.3829C34.1071 51.2479 35.4674 52.1956 35.4638 54.0856C35.4603 55.9407 34.0205 56.8646 31.1446 56.8574Z"
        fill="#323232"
      />
      <path
        d="M48.5064 45.2389C46.3684 45.2389 44.6454 45.9347 43.3376 47.3262C42.0298 48.7177 41.3737 50.4835 41.3692 52.6237C41.3638 54.7576 42.0033 56.5163 43.2878 57.8997C44.6279 59.3011 46.3571 60.0036 48.4755 60.0072C50.6136 60.0072 52.3365 59.3213 53.6443 57.9495C54.9521 56.5777 55.6109 54.8136 55.6208 52.6573C55.6208 50.5216 54.9718 48.7531 53.6739 47.3517C52.376 45.9504 50.6535 45.2461 48.5064 45.2389ZM51.0956 55.7049C50.4888 56.649 49.6177 57.1192 48.4822 57.1156C47.3467 57.1121 46.4774 56.6382 45.8742 55.6941C45.3841 54.9185 45.1404 53.9067 45.1431 52.6587C45.1431 51.4312 45.3909 50.4114 45.8863 49.5991C46.4931 48.6549 47.3647 48.1842 48.501 48.1869C49.6374 48.1896 50.5063 48.6733 51.1077 49.6381C51.5978 50.4513 51.8419 51.4631 51.8401 52.6735C51.8374 53.9233 51.5892 54.9351 51.0956 55.7089V55.7049Z"
        fill="#323232"
      />
      <path
        d="M70.9011 56.6853L70.924 45.6021L67.5189 45.5953L67.4974 55.8837C67.4974 55.9416 67.4974 56.0182 67.5109 56.111C67.5243 56.2038 67.5337 56.3276 67.5526 56.4795C66.4755 56.892 65.5195 57.0977 64.6848 57.0968C62.8124 57.0968 61.878 56.2258 61.8816 54.4837L61.9004 45.5832H58.4954L58.4752 55.0217C58.4689 58.366 60.5275 60.0426 64.6511 60.0516C66.3341 60.0516 68.4821 59.5929 71.095 58.6757C71.0196 58.2225 70.9698 57.8311 70.9415 57.4989C70.9132 57.1668 70.8997 56.8924 70.9011 56.6853Z"
        fill="#323232"
      />
      <path
        d="M81.337 45.3115C80.2304 45.2976 79.1377 45.5582 78.1568 46.0701L78.1716 39.9427H74.7666L74.7289 57.4653C74.7289 57.6549 74.7127 57.8903 74.6845 58.1741C74.6562 58.4579 74.6212 58.7873 74.5835 59.1653C76.3214 59.787 78.1554 60.098 80.0014 60.0838C82.1942 60.0838 84.0401 59.3979 85.5391 58.0261C87.0552 56.631 87.8154 54.8657 87.8199 52.73C87.8244 50.5943 87.289 48.8549 86.2137 47.5118C85.0441 46.0495 83.4185 45.316 81.337 45.3115ZM83.0739 55.8017C82.3558 56.7081 81.381 57.1596 80.1495 57.156C79.455 57.1716 78.7632 57.0651 78.1057 56.8413C78.1234 56.7577 78.1328 56.6726 78.1339 56.5871V56.4163L78.1501 48.9333C78.7371 48.485 79.5131 48.2609 80.478 48.2609C81.6691 48.2609 82.5762 48.7069 83.1991 49.5991C83.7466 50.4132 84.019 51.4245 84.0164 52.6331C84.0155 53.9341 83.7017 54.9907 83.0752 55.803L83.0739 55.8017Z"
        fill="#323232"
      />
      <path
        d="M117.396 50.0066C117.396 48.4384 116.897 47.2567 115.899 46.4614C114.956 45.7442 113.689 45.3824 112.1 45.3761C110.169 45.3645 108.259 45.7778 106.507 46.5865L107.665 49.1687C108.909 48.6221 110.252 48.3356 111.611 48.3268C113.2 48.3268 113.993 48.763 113.991 49.6354C113.991 50.2585 113.234 50.7391 111.72 51.0771C109.466 51.563 108.046 51.9567 107.46 52.2579C105.946 53.0299 105.186 54.2282 105.182 55.8528C105.182 57.3662 105.9 58.5018 107.336 59.2594C108.451 59.8476 109.85 60.1434 111.534 60.147C113.123 60.147 115.138 59.792 117.581 59.0819C117.468 58.4927 117.403 57.8955 117.386 57.2959L117.396 50.0066ZM111.564 57.3429C109.803 57.3429 108.925 56.7646 108.929 55.608C108.929 55.0037 109.279 54.5509 109.98 54.2497C110.303 54.1009 111.031 53.9041 112.167 53.6593C113.076 53.4719 113.682 53.2276 113.985 52.9263L113.978 56.4015C113.978 56.4725 113.983 56.5435 113.991 56.614C114.001 56.7001 114.014 56.809 114.033 56.9408C113.235 57.1985 112.402 57.334 111.564 57.3429Z"
        fill="#323232"
      />
      <path
        d="M127.536 45.4084C125.908 45.4084 123.76 45.876 121.092 46.8111C121.167 47.2657 121.218 47.6579 121.246 47.9879C121.273 48.3178 121.287 48.5868 121.286 48.7948L121.263 59.8781H124.668L124.69 49.5896C124.69 49.513 124.69 49.4283 124.676 49.3341C124.663 49.24 124.652 49.1257 124.635 48.9939C125.712 48.5796 126.667 48.3734 127.501 48.3752C129.373 48.3752 130.308 49.2467 130.306 50.9897L130.287 59.8982H133.693L133.712 50.4598C133.719 47.1012 131.661 45.4174 127.536 45.4084Z"
        fill="#323232"
      />
      <path
        d="M96.9795 56.4271L92.9403 45.6478L89.1125 45.6424L94.3971 58.2924C94.8118 59.2787 95.3405 59.8373 95.9832 59.9682C95.3414 61.5901 94.3626 62.7669 93.0467 63.4985L94.8509 66C96.5347 65.0765 97.8479 63.2945 98.7904 60.6541L104.214 45.6706L100.611 45.6639L96.9795 56.4271Z"
        fill="#323232"
      />
    </svg>
  );
};

export default BoubyanBankLogoSvg;
