import Link from "@Components/common/Link";
import { mq } from "@Utils/StyleUtil";
import Image from "next/image";
import styled, { css } from "styled-components";

export const LigveImageContainer = styled.div<{ readonly listView?: boolean }>`
  max-width: ${({ listView }) => (listView ? "76px" : "60px")};
  min-width: ${({ listView }) => (listView ? "76px" : "60px")};
  max-height: ${({ listView }) => (listView ? "76px" : "60px")};
  min-height: ${({ listView }) => (listView ? "76px" : "60px")};
`;
export const InstructorImage = styled(Image)`
  border-radius: 50%;
`;
export const InstructorImgPlaceHolder = styled.div`
  display: block;
  max-width: 60px;
  min-width: 60px;
  max-height: 60px;
  min-height: 60px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray7};
`;
export const Arrow = styled.img`
  /* display: none; */
  max-width: 7.64px;
  min-width: 7.64px;
  max-height: 13.56px;
  min-height: 13.56px;
  transform: none /*rtl: scale(-1, 1) */;
  ${mq(992)} {
    display: block;
  }
`;
export const Content = styled.div``;

export const Date = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  color: ${({ theme }) => theme.colors.gray2};
  line-height: 24px;
  margin-bottom: 4px;
  position: relative;
  padding-left: 11px;
  &::before {
    position: absolute;
    content: "";
    max-width: 5px;
    min-width: 5px;
    max-height: 5px;
    min-height: 5px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.red};
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
`;

export const CourseName = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  color: ${({ theme }) => theme.colors.nero};
  line-height: 28px;
  margin-bottom: 4px;
  max-height: calc(16px * 1.8 * 2);
`;

export const InstructorName = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  color: ${({ theme }) => theme.colors.gray2};
  line-height: 24px;
  margin-bottom: 8px;
`;

export const Price = styled.p`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  color: ${({ theme }) => theme.colors.seagule};
  background-color: ${({ theme }) => theme.colors.seagullNeutrals};
  line-height: 24px;
  border-radius: 40px;
  padding: 0 12px;
`;

export const Wrapper = styled(Link)<{
  readonly listView?: boolean;
}>`
  display: flex;
  cursor: pointer;
  align-items: flex-start !important;
  width: 316px;
  height: 184px;
  background-color: #fff;
  padding: 16px;
  gap: 16px;
  border-radius: 12px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  ${({ listView }) =>
    listView &&
    css`
      width: 100%;
      align-items: center;
      padding: 0;
      height: auto;
      box-shadow: none;
      background-color: transparent;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-radius: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray6};
      ${InstructorImage} {
        max-width: 76px;
        min-width: 76px;
        max-height: 76px;
        min-height: 76px;
      }
      ${Content} {
        & + img {
          margin: 0 8px 0 auto;
        }
      }
    `}
`;
// export const
